export const skills = [
  { skill: "Modern C++" },
  { skill: "Java & Kotlin" },
  { skill: "Typescript & Javascript" },
  { skill: "HTML & CSS" },
  { skill: "Database Design" },
  { skill: "Distributed Systems" },
  { skill: "Embedded Systems" },
  { skill: "Clean Architecture" },
];
