export const lightTheme = {
  body: "var(--clr-white)",
  text: "var(--clr-black)",
  toggleBorder: "none",
  background: "var(--clr-hover)",
  transform: "translateX(30px)",
};

export const darkTheme = {
  body: "var(--clr-black)",
  text: "var(--clr-white)",
  toggleBorder: "none",
  background: "var(--clr-highlight)",
  transform: "translateX(0)",
};
